<template>
  <div>
    <c-search-box @enter="getList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-plant type="search" name="plantCd" v-model="searchParam.plantCd" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-select
            :editable="editable"
            codeGroupCd="SAS_KIND_CD"
            label="구분"
            itemText="codeName"
            itemValue="code"
            name="safetyKindCd"
            type="search"
            v-model="searchParam.safetyKindCd">
          </c-select>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-select
            :editable="editable"
            codeGroupCd="SAS_TYPE_CD"
            label="보호구종류"
            itemText="codeName"
            itemValue="code"
            name="safetyTypeCd"
            type="search"
            v-model="searchParam.safetyTypeCd">
          </c-select>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <!-- 사용여부 -->
          <c-select
            :comboItems="useFlagItems"
            type="search"
            itemText="codeName"
            itemValue="code"
            name="useFlag"
            label="LBLUSEFLAG"
            v-model="searchParam.useFlag"
          ></c-select>
        </div>
      </template>
    </c-search-box>
    <c-table
      ref="grid"
      title="보호구 목록"
      tableId="grid"
      :columns="grid.columns"
      :data="grid.data"
      :editable="editable"
    >
      <!-- 버튼 영역 -->
      <template slot="table-button">
        <q-btn-group outline >
          <c-btn v-if="editable" label="LBLADD" icon="add" @btnClicked="addrowMst" />
          <c-btn 
            v-if="editable" 
            :isSubmit="isSave"
            :url="saveUrl"
            :param="grid.data"
            mappingType="PUT"
            label="LBLSAVE" 
            icon="save" 
            @beforeAction="saveMst"
            @btnCallback="saveCallback"/>
          <c-btn v-if="editable" label="LBLSEARCH" icon="search"  @btnClicked="getList"/>
        </q-btn-group>
      </template>
    </c-table>
  </div>
</template>

<script>
import { uid } from 'quasar'
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'safety-gear',
  data() {
    return {
      grid: {
        columns: [],
        data: [],
      },
      searchParam: {
        plantCd: null,
        useFlag: 'Y',
        safetyKindCd: null,
        safetyTypeCd: null,
      },
      useFlagItems: [
        { code: 'Y', codeName: '사용' },
        { code: 'N', codeName: '미사용' },
      ],
      editable: true,
      listUrl: '',
      saveUrl: '',
      isSave: false,
      deleteUrl: '',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      this.listUrl = selectConfig.sop.sas.safetyGear.list.url;
      this.saveUrl = transactionConfig.sop.sas.safetyGear.save.url;
      this.setHeader();
      this.getList();
    },
    setHeader() {
      this.$comm.getComboItems('SAS_KIND_CD').then(_result0 => {
        this.$comm.getComboItems('SAS_UNIT_CD').then(_result1 => {
          this.$comm.getComboItems('SAS_TYPE_CD').then(_result2 => {
            this.grid.columns = [
              {
                required: true,
                name: 'plantCd',
                field: 'plantCd',
                label: 'LBLPLANT',
                style: 'width:120px',
                align: 'left',
                type: 'plant',
                sortable: false,
              },
              {
                required: true,
                name: 'safetyKindCd',
                field: 'safetyKindCd',
                label: '보호구구분',
                align: 'center',
                style: 'width:120px',
                sortable: false,
                type: 'select',
                setHeader: true,
                comboItems: _result0
              },
              {
                required: true,
                name: 'safetyTypeCd',
                field: 'safetyTypeCd',
                label: '보호구종류',
                align: 'center',
                style: 'width:250px',
                sortable: false,
                type: 'select',
                setHeader: true,
                comboItems: _result2
              },
              {
                required: true,
                name: 'safetyGearName',
                field: 'safetyGearName',
                label: '보호구명',
                align: 'left',
                type: 'text',
                sortable: false,
              },
              {
                name: 'supplyVendorName',
                field: 'supplyVendorName',
                label: '공급업체명',
                align: 'left',
                type: 'text',
                style: 'width:250px',
                sortable: true,
              },
              {
                name: 'unitCd',
                field: 'unitCd',
                label: 'LBLUNIT',
                align: 'center',
                style: 'width:120px',
                sortable: false,
                type: 'select',
                setHeader: true,
                comboItems: _result1
              },
              // {
              //   required: true,
              //   name: 'price',
              //   field: 'price',
              //   label: '단가(원)',
              //   align: 'right',
              //   type: 'number',
              //   style: 'width:250px',
              //   sortable: true,
              // },
              {
                name: 'attach',
                field: 'attach',
                label: '보호구 사진',
                align: 'center',
                style: 'width:200px',
                type: 'attach',
                taskClassCd: 'SAFETY_GEAR',
                keyText: 'safetyGearId',
                sortable: false,
              },
              {
                name: 'useFlag',
                field: 'useFlag',
                label: '사용여부',
                align: 'center',
                style: 'width:80px',
                type: 'check',
                true: 'Y',
                false: 'N',
                sortable: false,
              },
            ]
          });
        });
      });
    },
    getList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },  
    addrowMst() {
      this.grid.data.push({
        plantCd: '',
        safetyGearId: uid(),
        safetyTypeCd: null,
        safetyGearName: '',
        supplyVendorName: '',
        unitCd: null,
        price: '',
        useFlag: 'Y',
        regUserId: this.$store.getters.user.userId,
        chgUserId: this.$store.getters.user.userId,
        editFlag: 'C',
      })
    },
    saveMst() {
      if (this.$comm.validTable(this.grid.columns, this.grid.data)) {
        window.getApp.$emit('CONFIRM', {
          title: 'LBLCONFIRM',
          message: 'MSGSAVE', // 저장하시겠습니까?
          // TODO : 필요시 추가하세요.
          type: 'info', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.$_.forEach(this.grid.data, item => {
              item.regUserId = this.$store.getters.user.userId;
              item.chgUserId = this.$store.getters.user.userId;
            });
            this.isSave = !this.isSave;
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
    saveCallback() {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.getList();
    },
  }
};
</script>
